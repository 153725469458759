.PreviousApplicationMenu {
    width: 100%;
    min-height: 200px;
    background: #FFFFFF;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 20px 26px 20px 20px;
    margin-top: 28px;
}
.PreviousApplicationMenu__title {
    font-size: 18px;
    text-align: center;
    line-height: 25px;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: #4D525B;
    font-weight: normal;
    margin: 0 0 21px 0;
    padding: 0;
}
.PreviousApplicationMenu__line {
    width: 100%;
    height: 1px;
    background: #C4C4C4;
    margin-bottom: 10px;
}
.PreviousApplicationMenu__date {
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #E34A4E;
    margin: 0;
    padding: 0;
}
.PreviousApplicationMenu__name_check {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 30px;
}
.PreviousApplicationMenu__name {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #E34A4E;
    margin: 0;
    padding: 0;
}
.PreviousApplicationMenu__check {
    width: 14px;
    height: 14px;
    background: url('../../../assets/icons/ic_check_red.svg') center no-repeat;
    margin-right: 21px;
    padding: 0 5px;
}
.PreviousApplicationMenu__description {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #E34A4E;
    padding: 0;
    margin: 35px 0 42px 0;
}