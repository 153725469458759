.CHeaderForm {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.11);
    height: 65px;
}
.HeaderForm-icons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 50%;
    background-color: #fff;
}
.HeaderForm-profile {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 50%;
    background-color: #fff;
}
.HeaderForm-profile > * {
    margin-right: 75px;
}
.HeaderForm-icons > *:first-child {
    width: 7px;
    height: 100%;
    background: #4FC6E0;
}
.HeaderForm-icons .vcc-logo, .HeaderForm-icons .invision-logo, .HeaderForm-icons .avitim-logo {
    height: 90%;
}
.HeaderForm-icons .vcc-logo {
    background: url("/src/assets/images/vcc_logo.svg")center no-repeat;
    background-size: 100px;
    width: 150px;
}
.HeaderForm-icons .invision-logo {
    background: url("/src/assets/images/inv-logo.svg")center no-repeat;
    background-size: 140px;
    width: 140px;
}
.HeaderForm-icons .avitim-logo {
    background: url("/src/assets/images/avitim_logo.svg")center no-repeat;
    background-size: 80px;
    width: 120px;
}
.HeaderForm-profile .text {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.01em;

    color: #4D525B;
}
.HeaderForm-profile .info {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.HeaderForm-profile .info .icon {
    background-color: #C4C4C4;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
}
.HeaderForm-profile .info .icon > * {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.01em;

    color: #FFFFFF;
}