.VideoForm {
    display: flex;
    flex-direction: column;
}
.VideoForm-body {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-left: 40px;
    margin-top: 40px;
}

.VideoForm-body .conferenceVideo {
    display: flex;
    flex-direction: column;
    width: 70%;
    height: 70%;
}
.VideoForm-body .conferenceVideo video {
    width: 100%;
}
.VideoForm-body .comment {
    display: flex;
    flex-direction: column;
    width: 25%;
    background-color: #FFF;
    margin-left: 20px;
    padding-right: 20px;
    padding-left: 20px;
    border: none;
}
.VideoForm-body .comment .title {
    border-bottom: solid 1px #C4C4C4;
    font-weight: normal;
    font-size: 20px;
    padding-bottom: 15px;
    font-family: Source Sans Pro;
    color: #4D525B;
    padding-left: 10px;
}

.VideoForm-body .comment .comments {
    border-bottom: solid 1px #C4C4C4;
    height: 80%;
}

.VideoForm-body .comment .comments .nameAndDate {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 15px;
    font-weight: bold;
    font-size: 18px;
    font-family: Source Sans Pro;
    color: #4D525B;
    margin-left: 10px;
}

.VideoForm-body .comment .comments .content {
    margin-top: 15px;
    font-weight: normal;
    font-size: 16px;
    font-family: Source Sans Pro;
    color: #4D525B;
    margin-left: 10px;
}

.VideoForm-body .comment .putCommentInput {
    margin-top: 25px;
    background: #FFFFFF;
    width: 100%;
    height: 67px;
    padding-left: 18px;
    border: 1px solid #C0C0C0;
    box-sizing: border-box;
    border-radius: 4px;
    margin-bottom: 25px;
}
.putCommentInput::placeholder {
    font-size: 16px;
    font-family: "Source Sans Pro";
}
.commentSend {
    position: relative;
    width: 43px;
    height: 43px;
    border-radius: 50%;
    background: #4FC6E0;
    border: none;
    margin: 0;
    bottom: 80px;
    left: 80%;
    padding-left: 2px;
    cursor: pointer;
}
.commentSend > div {
    border: solid white;
    border-width: 0 3px 3px 0;
    width: 8px;
    height: 8px;
    border-radius: 2px;
    display: inline-block;
    padding: 2px;
    transform: rotate(-45deg);
}

.reference {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    margin-bottom: 25px;
}
.reference .reference-content {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.01em;
    color: #4D525B;
    margin-bottom: 20px;
}
.reference .refInput {
    display: flex;
    flex-direction: row;
}

.refInput .refInput-input {
    width: 40%;
    height: 45px;
    border: 1px solid #C0C0C0;
    box-sizing: border-box;
    border-radius: 4px 0 0 4px;
    padding-left: 20px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-size: 16px;
}

.refInput-input::placeholder {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.01em;
    color: #C0C0C0;
}
.refInput-input:disabled {
    background-color: #FFF;
}

.reference .copyLinkBtn {
    width: 20%;
    height: 45px;
    background: #E34A4E;
    border-radius: 0 4px 4px 0;
    border: none;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
}
.reference .copyLinkBtn:hover {
    cursor: pointer;
    background: #c42e32;
}
.message {
    position: relative;
    height: 120px;
}

.VideoForm-bottom {
    margin-left: 2.5%;
    display: flex;
    position: relative;
    width: 92%;
    margin-right: 2.5%;
    flex-direction: row;
}