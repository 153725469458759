.WhiteButton, .create-conferenceBtn {
    width: 105%;
    height: 44px;
    background: white;
    cursor: pointer;
    color: #BDBDBD;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    border: 1px solid #BDBDBD;
    border-radius: 4px;
    outline: none;
    margin: 10px 0;
    padding: 10px 0;
}
@media (max-width: 425px) {
    .WhiteButton, .create-conferenceBtn {
        display: none;
    }
}

.WhiteButton:hover, .create-conferenceBtn:hover {
    color: #E34A4E;
    border-color: #E34A4E;
}
.WhiteButton:active, .create-conferenceBtn:active {
    transform: scale(0.99);
}

.WhiteButton--heightStabilizer {
    margin-top: auto;
    display: block;
}

.create-conferenceBtn {
    background: url("/src/assets/icons/video-call_icon.svg") no-repeat 25px #FFFFFF;
    background-size: 35px;
    padding-left: 40px;
}
.WhiteButton-socialContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #fff;
    border-radius: 8px;
    height: 50px;
    justify-content: space-around;
    width: 102%;
    padding-left: 10px;
}
/*.WhiteButton-socialContent > * {*/
/*    margin-right: 5px;*/
/*}*/
.social-instagram, .social-meta, .social-linkedin, .social-youtube {
    width: 30px;
    height: 30px;
}

.social-instagram {
    background-image: url("/src/assets/icons/instagram_icon.svg");
}
.social-meta {
    background-image: url("/src/assets/icons/meta_icon.svg");
}
.social-linkedin {
    background-image: url("/src/assets/icons/linkedin_icon.svg");
}
.social-youtube {
    background-image: url("/src/assets/icons/youtube_icon.svg");
}