.ResultsTableWorker {
    width: 100%;
    margin: 10px auto;
}
.ResultsTableWorker__filtersBlock {
    height: 47px;
    padding: 0 20px;
    background: #E0E0E0;
    border-radius: 8px 8px 0px 0px;
    display: flex;
    align-items: center;
}
.ResultsTableWorker__filtersBlock>*{
    width: 20%;
    box-sizing: border-box;
    padding: 0 10px;
}
.ResultsItemWorker>*{
    width: 20%;
    box-sizing: border-box;
    padding: 0 10px;
}
.ResultsTableWorker__filterWithIcon {
    display: flex;
    align-items: center;
}
.ResultsTableWorker__filterText {
    font-weight: bold;
    font-size: 18px;
    letter-spacing: 0.01em;
    color: #333333;
}
.ResultsTableWorker__filterIcon {
    z-index: 3;
    width: 16px;
    height: 16px;
    background: url('../../../assets/icons/ic-nail-filter.svg')center/cover no-repeat;
    transform: translate(10px, 2px);
    cursor: pointer;
}

.ResultsTableWorker__filter--status {

    position: relative;
}
.ResultsTableWorker__filter--subject {
    /* flex-basis: 18%; */
}
.ResultsTableWorker__filter--department {
    /* flex-basis: 19%; */
    position: relative;
}
.ResultsTableWorker__filter--specialist {
    /* flex-basis: 23%; */
}
.ResultsTableWorker__filter--worker {
    /* flex-basis: 20%; */
    position: relative;
}
.ResultsTableWorker__filter--regions {
    /* flex-basis: 15%; */
    position: relative;
}
.ResultsTableWorker__filter--results {
    /* flex-basis: 23%; */
}


.ResultsTableWorker__filterIcon--active {
    z-index: 3;
    background: url('../../../assets/icons/ic-nail-filter-active.svg')center/cover no-repeat;
}
