.LogoFrame {
    width: 100%;
    box-sizing: border-box;
    min-height: 187px;
    border-radius: 8px;
    background: white url('../../../assets/images/logo_with_text.svg')center no-repeat;
    background-size: 200px;
    cursor: pointer;
}

@media (max-width: 1280px) {
    .LogoFrame {
        background-size: 170px;
        min-height: 150px;
    }
}

@media (max-width: 425px) {
    .LogoFrame {
        background-size: 125px;
        min-height: 80px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.35);
    }
}