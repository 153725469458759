.StatusNew {
    height: 187px;
    background: #E34A4E;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 50px;
}
.StatusNew-warn {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 60px;
    margin-bottom: 15px;
    width: 50%;
}
.StatusNew__title {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.01em;
    color: #FFFFFF;
}
.StatusNew_text {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    margin: 0 60px 0 0;
}
.StatusNew__time {
    font-family: Source Sans Pro;
    font-style: normal;
    font-size: 130px;
    line-height: 163px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #FFFFFF;
}

@media (max-width: 1300px) {
    .StatusNew__time {
        font-size: 100px;
    }
    .StatusNew_text {
        font-size: 16px;
    }
}


.SpecialistWindow {
    width: 100%;
    height: 190px;
    /*min-height: 200px;*/
    background: white;
    border-radius: 8px;
    /*padding: 36px 70px;*/
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.SpecialistWindow-status-color {
    width: 25px;
    height: 100%;
    border-radius: 8px 0 0 8px;
}
.color-green {
    color: #3CC13B;
}
.color-orange {
    color: #F3BB1C;
}
.color-grey {
    color: #4B4C4D;
}

.backColor-grey {
    background-color: #4B4C4D;
}
.backColor-green {
    background-color: #3CC13B;
}
.backColor-orange {
    background-color: #F3BB1C;
}
.SpecialistWindow-title {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 45px;
    letter-spacing: 0.01em;
    width: 180px;
    margin-left: 25px;
}
.SpecialistOnTheWay-text {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 38px;
    width: 115%;
    /* identical to box height */
    letter-spacing: 0.01em;
    color: #4D525B;
}
.SpecialistWindow-content {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-grow: 1;
    height: 100%;
    margin-right: 20px;
    margin-left: 20px;
    justify-content: space-around;
    /*background-color: #9E071E;*/
    align-items: center;
}
.SpecialistWindow-content-avatar {
    width: 100px;
    height: 100px;
    margin-right: 20px;
}
.SpecialistWindow-content-info {
    display: flex;
    flex-direction: column;
}
.SpecialistWindow-content-info .name {
    color: #4D525B;
    font-size: 20px;
    font-weight: bold;
}
.SpecialistWindow-content-info .contact {
    display: flex;
    flex-direction: column;
}
.SpecialistWindow-content-info .department, .phone {
    font-size: 16px;
    margin-right: 10px;
    color: #4D525B;
}

.ratingBlock {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100px;
    margin: 0px 15px;
}
.ratingBlock > * {
    margin-bottom: 15px;
}
.ratingBlock .options {
    display: flex;
    flex-direction: row;
}
.prompt, .leaveOpinion {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    line-height: 15px;
    /* identical to box height */
    color: #C4C4C4;
    letter-spacing: 0.01em;
}
.prompt {
    font-size: 12px;
}
.leaveOpinion {
    font-size: 16px;
}
.likeBtn, .dislikeBtn {
    width: 50px;
    height: 50px;
    margin-right: 15px;
    cursor: pointer;
}

.likeBtn {
    background: url('../../../assets/icons/icon-like.svg')center no-repeat;
}
.dislikeBtn {
    background: url('../../../assets/icons/icon-dislike.svg')center no-repeat;
}

.likeBtn-active {
    background: url('../../../assets/icons/like-active.svg')center no-repeat;
}

.dislikeBtn-active {
    background: url('../../../assets/icons/icon-dislike__active.svg')center no-repeat;
}

.likeBtn:hover {
    background: url('../../../assets/icons/like-active.svg')center no-repeat;
}
.dislikeBtn:hover {
    background: url('../../../assets/icons/icon-dislike__active.svg')center no-repeat;
}

.SpecialistWindow-body {
    display: flex;
    flex-direction: row;
    align-items: center;
}

@media (max-width: 425px) {
    .SpecialistWindow {
        flex-direction: row;
    }
    .SpecialistWindow-body {
        flex-direction: column;
        margin-left: 20px;
        align-items: flex-start;
    }
    .SpecialistWindow-title {
        width: 100%;
        margin: 0 0 15px 0;
        font-size: 24px;
    }
    .SpecialistWindow-content {
        margin: 0;
        width: 100%;
        justify-content: flex-start;
        flex-direction: row;
    }
    .SpecialistWindow-status-color {
        width: 15px;
        height: 100%;
        border-radius: 0;
    }
    .ratingBlock {
        width: 15%;
        align-items: center;
        margin-left: 20px;
        margin-top: 25px;
    }
    .ratingBlock .options {
        flex-direction: column;
    }
    .ratingBlock .options > * {
        margin-bottom: 10px;
    }
    .ratingBlock .leaveOpinion, .prompt {
        display: none;
    }
    .StatusNew__title {
        font-size: 12px;
    }
    .StatusNew {
        border-radius: 0;
    }
    .StatusNew_text {
        font-size: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 5; /* number of lines to show */
        line-clamp: 5;
        line-height: 1.5;
        margin: 0;
        -webkit-box-orient: vertical;
    }
    .StatusNew-warn {
        padding-left: 20px;
    }

    .SpecialistOnTheWay-text {
        width: 100%;
        font-size: 23px;
    }
}

@media (max-width: 375px) {

    .StatusNew {
        padding-right: 15px;
    }

    .StatusNew__timeBlock {
        margin-left: 25px;
    }
    .StatusNew__time {
        font-size: 75px;
    }

    .SpecialistOnTheWay-text {
        font-size: 20px;
    }
}

@media (max-width: 320px) {
    .StatusNew__time {
        font-size: 60px;
    }

    .SpecialistOnTheWay-text {
        font-size: 18px;
    }

    .SpecialistWindow-content-avatar {
        width: 65px;
        height: 65px;
    }
}