.ReviewPopUp__Container {
  box-sizing: border-box;
  width: 770px;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 8px;
  padding: 37px 65px 46px 70px;
}

.ReviewPopUp__PhotoMeta__Rating {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 31px;
}

.ReviewPopUp__Photo__Meta {
  display: flex;
  justify-content: center;
  gap: 17px;
}

.ReviewPopUp__Photo {
  border-radius: 50%;
}
.ReviewPopUp__Photo img {
  max-width: 104px;
}

.ReviewPopUp__Meta {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 7px;
}

.ReviewPopUp__Name {
  font-weight: 700;
  font-size: 20px;
  color: #4d525b;
}

.ReviewPopUp__Department_Block {
  display: flex;
  flex-direction: row;
}

.ReviewPopUp__Department, .ReviewPopUp__Phone {
  font-weight: 400;
  font-size: 16px;
  color: #4d525b;
}

.ReviewPopUp__Phone {
  margin-left: 15px;
}

.ReviewPopUp__RequestNumber {
  font-weight: 700;
  font-size: 16px;
  color: #e34a4e;
}

.ReviewPopUp__Rating {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 34px;
  margin-right: 30px;
}

.ReviewPopUp__Form {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.ReviewPopUp__Form textarea {
  border: 1px solid #c0c0c0;
  border-radius: 4px;
  height: 114px;
  /*width: 99%;*/
  resize: none;
  margin-bottom: 21px;
  padding-left: 33px;
  padding-top: 30px;
}

.ReviewPopUp__Form textarea:focus {
  outline: none;
}

.ReviewPopUp__Form textarea:focus + .ReviewPopUp__Form__Textarea__Label {
  display: none;
}

.ReviewPopUp__Form span {
  position: absolute;
  font-weight: 400;
  font-size: 18px;
  color: #c0c0c0;
  width: 583px;
  top: 13%;
  left: 5%;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
}

.ReviewPopUp__Form button {
  width: 100%;
}

.ReviewPopUp__Review {
  display: flex;
  flex-direction: column;
  color: #4d525b;
  width: 100%;
}

.ReviewPopUp__Review__Label {
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 5px;
}
.ReviewPopUp__Review span {
  margin-bottom: 21px;
}

.ReviewPopUp__Review__Footer {
  display: flex;
  justify-content: space-between;
}

.ReviewPopUp__Review__Footer p {
  display: flex;
  align-items: flex-end;
  gap: 11px;
  font-weight: 700;
  font-size: 16px;
  color: #bdbdbd;
  position: relative;
}

.ReviewPopUp__Review__Credits {
  justify-content: start;
}

.ReviewPopUp__Review__Credits p {
  font-weight: 400;
  font-size: 14px;
}

.ReviewPopUp__Review__Credits span {
  font-weight: 700;
  font-size: 14px;
}

.Review__Credits__Icon {
  position: absolute;
  right: 130px;
  top: 15px;
}

.ReviewPopUp__Review__Credits__Btn {
  cursor: pointer;
}

.No__Select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
}

.Display__None {
  display: none;
}
