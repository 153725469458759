.EmployeeMenu {
    width: 100%;
    min-height: 150px;
    background: #FFFFFF;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 20px 26px 20px 20px;
    margin-top: 28px;
}
.EmployeeMenu-title {
    font-size: 18px;
    text-align: center;
    line-height: 25px;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: #4D525B;
    font-weight: normal;
    margin: 0 0 10px 0;
    padding: 0;
}
.EmployeeMenu-name {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #E34A4E;
    margin: 0;
    padding: 0;
}
.EmployeeMenu-description {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #E34A4E;
    padding: 0;
    margin: 15px 0 42px 0;
}