.ModalPeriod__bg {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(50, 50, 50, 0.5);
    cursor: pointer;
    z-index: 3;
}
.ModalPeriod__input {
    padding: 20px;
}
.ModalPeriod {
    background: #F3F6F9;
    border-radius: 8px;
    width: 1043px;
    height: 641px;
    box-sizing: border-box;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 4;
    padding: 20px 73px;
}
.ModalPeriod__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
}
.ModalPeriod__textTopButton {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.01em;
    text-align: right;
    color: #C53437;
}
.ModalPeriod__buttonsBlock {
    display: flex;
}
.ModalPeriod__btnDate {
    width: 93px;
    height: 24px;
    background: #FFFFFF;
    border: 1px solid #E34A4E;
    border-right: none;
    font-weight: bold;
    font-size: 12px;
    color: #E34A4E;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.2s;
}
.ModalPeriod__btnDate:hover {
    background: #E34A4E;
    color: white;
}
.ModalPeriod__btnDate--left {
    border-radius: 4px 0px 0px 4px;
}
.ModalPeriod__btnDate--right {
    border-right: 1px solid #E34A4E;
    border-radius: 0px 4px 4px 0px;
}

.ModalPeriod__chooseDateBlock {
    display: flex;
    align-items: center;
}
.ModalPeriod__dash {
    font-size: 20px;
    line-height: 15px;
    letter-spacing: 0.1em;
    color: #BDBDBD;
    margin: 0 3px;
}
.ModalPeriod__inputDate {
    width: 94px;
    height: 24px;
    background: #FFFFFF;
    border: 1px solid #BDBDBD;
    border-radius: 4px;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.1em;
    color: #BDBDBD;
    display: flex;
    justify-content: center;
    align-items: center;
}

.CalendarOne {
    width: 897px;
    height: 470px;
    background: #FFFFFF;
    border-radius: 4px;
    padding: 20px 28px;
    box-sizing: border-box;
}
.CalendarOne__month {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.12px;
    text-transform: uppercase;
    color: #333333;
    padding-left: 11px;
    cursor: pointer;
    user-select: none;
}
.CalendarOne__table {
    display: flex;
    flex-wrap: wrap;
}
.CalendarOne__column {
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.22px;
    color: #4F4F4F;
    padding: 10px 5px;
    margin: 10px 0;
    width: 110px;
    cursor: pointer;
    user-select: none;
}

.CalendarOne__row {}
.CalendarOne__weekdays-block {
    display: flex;
    align-items: center;
}
.CalendarOne__weekdays {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    text-transform: capitalize;
    color: #BDBDBD;
    user-select: none;
    width: 110px;
    padding: 0 5px;
}
.CalendarOne__arrow {
    width: 11px;
    height: 5px;
    margin-left: 10px;
    cursor: pointer;
    background: url('../../../assets/icons/calendar_arrow_down.svg')center no-repeat;
}
.CalendarOne__month-block {
    display: flex;
    align-items: center;
    position: relative;
}
.CalendarOne__month-modal {
    position: absolute;
    top: 50px;
    left: 0;
    z-index: 5;
    width: 150px;
    height: 200px;
    overflow-y: scroll;
    background: white;
    border-radius: 4px;
    border: 1px solid  black;
}
.CalendarOne__month-modal::-webkit-scrollbar-track
{
    border: 5px solid white;
    background-color: #b2bec3;
}

.CalendarOne__month-modal::-webkit-scrollbar
{
    width: 8px;
    background-color: #dfe6e9;
}

.CalendarOne__month-modal::-webkit-scrollbar-thumb
{
    background-color: #4FC6E0;
    border-radius: 20px;
}

.ModalPeriod__year {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.12px;
    color: #4F4F4F;
    cursor: pointer;
}
.ModalPeriod__year-block {
    display: flex;
    align-items: center;
    padding: 0 52px 0 25px;
    position: relative;
}
.ModalPeriod__year-arrow {
    width: 11px;
    height: 5px;
    margin-left: 10px;
    cursor: pointer;
    background: url('../../../assets/icons/calendar_arrow_down.svg')center no-repeat;
}


.RedButton--inCalendar {
    width: 200px;
    height: 30px;
    background: white;
    cursor: pointer;
    color: #E34A4E;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    border: 1px solid #E34A4E;
    border-radius: 4px;
    outline: none;
}

.RedButton--inCalendar:hover {
    color: white;
    border-color: #E34A4E;
    background: #E34A4E;
}
.RedButton--inCalendar:active {
    transform: scale(0.99);
}
.ModalPeriod__year-modal {
    position: absolute;
    top: 50px;
    left: 0;
    z-index: 5;
    width: 100px;
    height: auto;
    background: white;
    border-radius: 4px;
    border: 1px solid  black;
}



/*/////////////CALENDAR*/

.react-calendar {
    max-width: 100%;
    background: white;
    border-radius: 10px;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
}
.react-calendar--doubleView {
    width: 100%;
    padding: 30px;
    box-sizing: border-box;
    /*height: 400px;*/
}
.react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.react-calendar button {
    margin: 5px 0;
    border: 0;
    outline: none;
}

.react-calendar button:enabled:hover {
    cursor: pointer;
}
.react-calendar__navigation {
    height: 44px;
    margin-bottom: 1em;
}
.react-calendar__navigation button {
    min-width: 44px;
    background: none;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: #e6e6e6;
}
.react-calendar__navigation button[disabled] {
    background-color: #f0f0f0;
}
.react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 18px;
}
.react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
    font-style: normal;
    font-weight: bold;
    line-height: 16px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: #BDBDBD;

}
.react-calendar__month-view__weekNumbers {
    font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}
.react-calendar__month-view__days__day--weekend {
    color: #d10000;
}
.react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
}
.react-calendar__tile {
    max-width: 100%;
    text-align: center;
    padding: 0.75em 0.5em;
    background: none;
}
.react-calendar__tile:disabled {
    background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background-color: #e6e6e6;
}
.react-calendar__tile--now {
    background: #ffff76;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    background: #ffffa9;
}
.react-calendar__tile--hasActive {
    background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    background: #a9d4ff;
}
.react-calendar__tile--active {
    background: #4FC6E0;
    color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: #4FC6E0;
}
.react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
}
abbr  {
    font-size: 16px;
    text-decoration: none!important;
}
.react-calendar__tile--range {
    box-shadow: 0 0 1px 0 #4FC6E0;
}
.react-calendar__tile--rangeStart {
    border-radius: 12px 0 0 12px;
}
.react-calendar__tile--rangeEnd {
    border-radius: 0 12px 12px 0;
}
.react-calendar__navigation__label__labelText {
    font-weight: bold;
    font-size: 18px;
}
