.NewUserDataForm {
    display: flex;
    flex-direction: column;
    width: 95%;
    max-height: 500px;
    background: #FFFFFF;
    border: 3px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 2px 4px 14px rgba(77, 82, 91, 0.27);
    border-radius: 8px;
    max-width: 975px;
}

.NewUserDataForm-header-second {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
}

.NewUserDataForm-header-second .header {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.01em;
    margin-left: 30px;
    margin-right: 22px;
}

.NewUserDataForm-inputs {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-left: 30px;
}

.NewUserDataForm-inputs input, .NewUserDataForm-dateOfStart {
    width: 94%;
    height: 44px;
    background: #FFFFFF;
    border: 1px solid #C0C0C0;
    box-sizing: border-box;
    border-radius: 4px;
    padding-left: 30px;
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
    font-size: 18px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    line-height: 23px;
    /* identical to box height */
    letter-spacing: 0.01em;
    margin-bottom: 30px;
}

.NewUserDataForm-inputs input::placeholder {
    color: #c0c0c0;
}

.NewUserDataForm-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-left: 30px;
    flex-wrap: wrap;
}

.NewUserDataForm-dateOfStart {
    width: 50%;
}
.NewUserDataForm-dateOfStart::placeholder {
    color: #C0C0C0;
}
.NewUserDataForm-dateOfStart:disabled {
    background-color: #ececec;
}

.NewUserDataForm-radio {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-right: 125px;
    margin-bottom: 30px;
}

.NewUserDataForm-radio-text {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    margin-left: 5px;
    /* identical to box height */
    color: #4D4D4F;
}

.NewUserDataForm-radio input[type = "radio"] {
    /*box-sizing: border-box;*/
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.NewUser-workingCurrent {
    width: 22px;
    height: 22px;
    z-index: 1;
    cursor: pointer;
    appearance: none;
}
.workingCurrent-radioButton {
    position: relative;
    right: 25px;
    padding: 10px 10px;
    border: solid 2px #d7d7d7;
    border-radius: 50%;
}
.workingCurrent-radioButton .hiddenSelectButton {
    display: none;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    background-color: #4FC6E0;
}
.workingCurrent-text {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    color: #4D4D4F;
}