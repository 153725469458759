.ApplicationForm {
    width: 100%;
    margin-left: auto;
    height: 100%;
    background: white;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 50px 70px;
    display: flex;
    flex-direction: column;
}
.ApplicationForm__title {
    font-weight: 400;
    font-size: 26px;
    line-height: 35px;
    letter-spacing: 0.01em;
    color: #4D525B;
    margin: 0;
    padding: 0;
}
.ApplicationForm__title-name {
    font-weight: bold;
}
.ApplicationForm__inputTitle {
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.01em;
    color: #4D525B;
    margin-bottom: 10px;
}
.ApplicationForm__input {
    font-family: 'Source Sans Pro';
    color: #4D525B;
    width: 100%;
    height: 44px;
    background: white;
    border: 1px solid #C0C0C0;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 11px 22px;
    outline: none;
    font-size: 18px;
}
.ApplicationForm__input::placeholder {
    overflow: visible;
}
.ApplicationForm__input:focus {
    border: 1px solid #E34A4E;
    border-radius: 4px;
}
.ApplicationForm__input:focus::-webkit-input-placeholder {
    color: #E34A4E;
}
.ApplicationForm__input:focus::-moz-placeholder {
    color: #E34A4E;
}
.ApplicationForm__input:focus:-ms-input-placeholder {
    color: #E34A4E;
}
.ApplicationForm__input:focus:-moz-placeholder {
    color: #E34A4E;
}
.ApplicationForm__input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    font-family: "Source Sans Pro";
    color: #C0C0C0;
    font-size: 18px;
    line-height: 23px;
  }
.ApplicationForm__input::-moz-placeholder { /* Firefox 19+ */
    font-family: "Source Sans Pro";
    color:  #C0C0C0;
    font-size: 18px;
    line-height: 23px;
  }
.ApplicationForm__input:-ms-input-placeholder { /* IE 10+ */
    font-family: "Source Sans Pro";
    color: #C0C0C0;
    font-size: 18px;
    line-height: 23px;
  }
.ApplicationForm__input:-moz-placeholder { /* Firefox 18- */
    font-family: "Source Sans Pro";
    color: #C0C0C0;
    font-size: 18px;
    line-height: 23px;
  }
.ApplicationForm__input-select {
    cursor: pointer;
    font-family: 'Source Sans Pro';
    /*color: #4D525B;*/
    color: #C0C0C0;
    -moz-appearance:none; /* Firefox */
    -webkit-appearance:none; /* Safari and Chrome */
    appearance:none;
    background: url('../../../assets/icons/ic_arrow_select.svg')center right no-repeat;
    font-size: 18px;
}
.ApplicationForm__input-select:focus {
    color: #E34A4E;
}
.ApplicationForm__input-select:focus {
    color: #E34A4E;
}
.ApplicationForm__input-select:focus {
    color: #E34A4E;
}
.ApplicationForm__input-select:focus {
    color: #E34A4E;
}
/*.ApplicationForm__input-select:first-child {*/
/*    color: #C0C0C0;*/
/*}*/
.ApplicationForm__input-select option:not(:checked) {
    color: black;
}

.ApplicationForm__input-select:hover {
    background: url('../../../assets/icons/ic_arrow_select_red.svg')center right no-repeat;
}
.ApplicationForm__input-select:active {
    background: url('../../../assets/icons/ic_arrow_select_red.svg')center right no-repeat;
}


.ApplicationForm__input-optionDefault {
    /*color: #C0C0C0;*/
    display: none;
}
.ApplicationForm__inputMessage {
    width: 100%;
    box-sizing: border-box;
    padding: 20px;
    border: 1px solid #C0C0C0;
    border-radius: 4px;
    resize: none;
    min-height: 240px;
    outline: none;
    font-family: 'Source Sans Pro';
    font-size: 18px;
}
.ApplicationForm__inputMessage:focus {
    border: 1px solid #E34A4E;
    border-radius: 4px;
}
.ApplicationForm__inputMessage:focus::-webkit-input-placeholder {
    color: #E34A4E;
}
.ApplicationForm__inputMessage:focus::-moz-placeholder {
    color: #E34A4E;
}
.ApplicationForm__inputMessage:focus:-ms-input-placeholder {
    color: #E34A4E;
}
.ApplicationForm__inputMessage:focus:-moz-placeholder {
    color: #E34A4E;
}
.ApplicationForm__inputMessage::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    font-family: "Source Sans Pro";
    color: #C0C0C0;
    font-size: 18px;
    line-height: 20px;

  }
.ApplicationForm__inputMessage::-moz-placeholder { /* Firefox 19+ */
    font-family: "Source Sans Pro";
    color:  #C0C0C0;
    font-size: 18px;
    line-height: 22px;
  }
.ApplicationForm__inputMessage:-ms-input-placeholder { /* IE 10+ */
    font-family: "Source Sans Pro";
    color: #C0C0C0;
    font-size: 18px;
    line-height: 22px;
  }
.ApplicationForm__inputMessage:-moz-placeholder { /* Firefox 18- */
    font-family: "Source Sans Pro";
    color: #C0C0C0;
    font-size: 18px;
    line-height: 22px;
  }
.ApplicationForm__downloadBlock {
    margin: 20px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.ApplicationForm__inputFile {
    display: none;
}
.ApplicationForm__fileIcon {
    cursor: pointer;
    width: 30px;
    height: 27px;
    margin-right: 10px;
    background: url('../../../assets/icons/ic_add_file.svg')center no-repeat;
}
.ApplicationForm__fileName {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #4D525B;
    cursor: pointer;
    margin-right: 20px;
}
.ApplicationForm__question {
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: relative;
    background: url('../../../assets/icons/ic_question.svg')6px 4px  no-repeat, url('../../../assets/icons/ic_question_frame.svg')center center no-repeat;
}
.ApplicationForm__question--modal {
    position: absolute;
    bottom: 22px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 217px;
    padding: 0 20px;
    border: 1px solid #E34A4E;
    background: white;
    border-radius: 4px;
}
.ApplicationForm__question--modal-text {
    color: #4D525B;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
}
.ApplicationForm__fileIcon:hover {
    background: url('../../../assets/icons/ic_add_file_red.svg')center no-repeat;
}
.ApplicationForm__fileName:hover {
    color: #E34A4E;
}
.ApplicationForm__question:hover {
    background: url('../../../assets/icons/ic_question_red.svg')6px 4px  no-repeat, url('../../../assets/icons/ic_question_frame_red.svg')center center no-repeat;
}
.ApplicationForm__form {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.ApplicationForm__teamViewerTitle {
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.01em;
    color: #4D525B;
    margin: 10px 0;
}
.ApplicationForm__teamViewerTitle-block {
    display: flex;
    align-items: center;
    margin-top: auto;
}
.ApplicationForm__teamViewer-icon {
    width: 30px;
    height: 30px;
    display: block;
    background: url("../../../assets/icons/downloadTeamViewer.svg")center no-repeat;
}
.ApplicationForm__teamViewer-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}
.ApplicationForm__teamViewer-combine {
    display: flex;
    justify-content: flex-start;
    position: relative;
}
.ApplicationForm__inputPassword {
    width: 298px;
    height: 44px;
    border: 1px solid #C0C0C0;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 0 22px;
    outline: none;
    font-size: 18px;
}
.ApplicationForm__inputPassword:focus {
    border: 1px solid #E34A4E;
    border-radius: 4px;
}

.ApplicationForm__inputPassword::placeholder {
    overflow: visible;
}

.ApplicationForm__inputPassword::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    font-family: "Source Sans Pro";
    color: #C0C0C0;
    font-size: 18px;
    line-height: 20px;
  }
.ApplicationForm__inputPassword::-moz-placeholder { /* Firefox 19+ */
    font-family: "Source Sans Pro";
    color:  #C0C0C0;
    font-size: 18px;
    line-height: 20px;
  }
.ApplicationForm__inputPassword:-ms-input-placeholder { /* IE 10+ */
    font-family: "Source Sans Pro";
    color: #C0C0C0;
    font-size: 18px;
    line-height: 20px;
  }
.ApplicationForm__inputPassword:-moz-placeholder { /* Firefox 18- */
    font-family: "Source Sans Pro";
    color: #C0C0C0;
    font-size: 18px;
    line-height: 20px;
  }
.ApplicationForm__inputPassword:focus::-webkit-input-placeholder {
    color: #E34A4E;
}
.ApplicationForm__inputPassword:focus::-moz-placeholder {
    color: #E34A4E;
}
.ApplicationForm__inputPassword:focus:-ms-input-placeholder {
    color: #E34A4E;
}
.ApplicationForm__inputPassword:focus:-moz-placeholder {
    color: #E34A4E;
}
.ApplicationForm__eye {
    cursor: pointer;
    width: 28px;
    height: 20px;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate(0, -50%);
    background: url('../../../assets/icons/ic_eye_password.svg')center no-repeat;
}
.ApplicationForm__eye:hover {
    background: url('../../../assets/icons/ic_eye_password_red.svg')center no-repeat;
}
.ApplicationForm__eye:active {
    transform: translate(0, -50%) scale(0.9);
}

.FileListBlocks__block {
    display: flex;
    flex-wrap: wrap;
}
.FileListBlocks__item {
    margin: 0 3px 5px 0;
    padding: 5px;
    border: 1px solid #4D525B;
    border-radius: 5px;
    display: flex;
    align-items: center;

}
.FileListBlocks__text {
    margin: 0;
    padding: 0;
}
.FileListBlocks__iconDelete {
    width: 10px;
    height: 10px;
    background: url("../../../assets/icons/ic-close-blue.svg")center no-repeat;
    margin: 0 2px 0 10px;
    cursor: pointer;
}
.ApplicationForm__teamViewer-block .personalData {
    width: 20px;
    height: 20px;
    background: transparent;
    box-shadow: inset 2px 2px 3px rgba(0, 0, 0, 0.25);
    margin-right: 15px;
    appearance: none;
    z-index: 2;
    cursor: pointer;
}
.ApplicationForm__teamViewer-personal {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-top: 20px;
}
.ApplicationForm__teamViewer-personal .hiddenCheck {
    position: relative;
    right: 354px;
    z-index: 1;
    top: 1px;
    background: #00ACCD;
    width: 15px;
    height: 15px;
}