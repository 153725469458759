.about {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.about > * {
    margin-right: 25px;
}
.about p {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: #4D525B;
}
.about .chronology {
    width: 160px;
}
.about .itsg-logo {
    width: 50px;
    height: 44px;
    background: url("../../../assets/images/ITSG_logo_inverce.svg") right no-repeat;
}

@media (max-width: 425px) {
    .about {
        justify-content: center;
        margin-left: 25px;
        margin-top: 15px;
    }
    .about .chronology {
        display: none;
    }
    .about p {
        display: none;
    }
    .about .itsg-logo {
        width: 60px;
        height: 60px;
        background-size: 60px;
    }
}