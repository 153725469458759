.LogoSideBar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 60px;
    z-index: 0;
    background-color: #FFF;
    /*box-shadow: 0 2px 2px 10px #000;*/
}

@media (max-width: 425px) {
    .LogoSideBar {
        display: none;
    }
}

.sidebarColor {
    background-color: #FF0000;
    width: 6px;
    height: 100%;
}
.avitim {
    position: absolute;
    bottom: 85px;
    left: 17px;
    background: url("/src/assets/images/avitim_sidebar_logo.svg") center no-repeat;
    height: 150px;
    width: 30px;
}
.logo {
    position: absolute;
    bottom: 30px;
    left: 12px;
    background: url("/src/assets/images/avit_logo.svg") center no-repeat;
    background-size: 35px;
    width: 40px;
    height: 40px;
}