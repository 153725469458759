p {
    margin: 0;
  }
  
  .Comment {
    background: #c0c0c0;
    border-radius: 8px;
    padding: 10px;
    box-sizing: border-box;
    min-height: 100px;
    width: 400px;
    margin: 10px 0;
  }
  
  .ApplicationDetails {
    width: 100%;
    /*min-height: 383px;*/
    background: #ffffff;
    height: 95.5%;
    border-radius: 8px;
    padding: 30px 70px;
    box-sizing: border-box;
    margin-top: 30px;
  }
  .ApplicationDetails__title {
    font-weight: bold;
    font-size: 30px;
    line-height: 38px;
    letter-spacing: 0.01em;
    color: #4d4d4f;
    margin: 0 0 10px 0;
  }
  .ApplicationDetails__head {
    display: flex;
  }
  .ApplicationDetails__head--sides {
    margin-top: 30px;
    margin-right: 88px;
    min-width: 230px;
  }
  .ApplicationDetails__text--title {
    margin-top: 20px;
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.01em;
    color: #4d525b;
    margin-bottom: 7px;
  }
  .ApplicationDetails__text--title--high {
    margin-top: 20px;
  }
  .ApplicationDetails__text {
    font-weight: normal;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.01em;
    color: #4d525b;
    padding: 0;
    margin: 0 0 20px 0;
  }
  .ApplicationDetails__text--block {
    position: relative;
  }
  .ApplicationDetails__textarea {
    width: 100%;
    height: 160px;
    padding: 20px;
    box-sizing: border-box;
    background: #ffffff;
    border: 1px solid #c0c0c0;
    box-sizing: border-box;
    font-family: 'Source Sans Pro';
    border-radius: 4px;
    outline: none;
    margin-bottom: 20px;
    resize: none;
    margin-top: 40px;
    font-size: 18px;
  }
  .ApplicationDetails__textarea:focus {
    border: 1px solid #e34a4e;
  }
  .ApplicationDetails__textarea:focus::-webkit-input-placeholder {
    color: #e34a4e;
  }
  .ApplicationDetails__textarea:focus::-moz-placeholder {
    color: #e34a4e;
  }
  .ApplicationDetails__textarea:focus:-ms-input-placeholder {
    color: #e34a4e;
  }
  .ApplicationDetails__textarea:focus:-moz-placeholder {
    color: #e34a4e;
  }
  .ApplicationDetails__btn {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  
  .ApplicationDetails__textarea::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #c0c0c0;
    font-size: 18px;
    line-height: 20px;
    font-family: 'Source Sans Pro';
  }
  .ApplicationDetails__textarea::-moz-placeholder {
    /* Firefox 19+ */
    color: #c0c0c0;
    font-size: 18px;
    line-height: 20px;
    font-family: 'Source Sans Pro';
  }
  .ApplicationDetails__textarea:-ms-input-placeholder {
    /* IE 10+ */
    color: #c0c0c0;
    font-size: 18px;
    line-height: 20px;
    font-family: 'Source Sans Pro';
  }
  .ApplicationDetails__textarea:-moz-placeholder {
    /* Firefox 18- */
    color: #c0c0c0;
    font-size: 18px;
    line-height: 20px;
    font-family: 'Source Sans Pro';
  }
  
  .ApplicationDetails__message--button {
    color: #c0c0c0;
    font-size: 12px;
    cursor: pointer;
    display: block;
    width: 100%;
    text-align: right;
    background: linear-gradient(to right, rgba(255, 255, 255, 0.3), white 80%);
    position: absolute;
    right: 0;
    bottom: 0;
  }
  
  .ApplicationDetails__content__text {
    font-weight: normal;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.01em;
    color: #4d525b;
    padding: 0;
    margin: 5px 0 0 0;
  }
  .ApplicationDetails__content__text--commentDate {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.01em;
  
    color: #4d525b;
  }
  .messageClassToggleHidden {
    height: 125px;
    position: relative;
    overflow: hidden;
  }
  .messageClassToggleHidden--empty {
    min-height: 70px;
  }
  .messageClassToggleOpen {
    position: relative;
  }
  .messageClassToggleOpen > .ApplicationDetails__message--button {
    background: none;
    position: relative;
  }
  @media (max-width: 425px) {
    .ApplicationDetails {
      padding: 0;
    }
    .ApplicationDetails__title {
      display: none;
    }
    .ApplicationDetails__head {
      flex-direction: column;
    }
    .ApplicationDetails__text--title,
    .ApplicationDetails__text {
      margin-left: 30px;
    }
    .ApplicationDetails__textarea {
      width: 90%;
      margin-right: 5%;
      margin-left: 5%;
    }
    .ApplicationDetails__btn {
      justify-content: center;
    }
  }
  