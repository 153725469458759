.RefreshOrCreateForm {
    display: flex;
    flex-direction: column;
    width: 95%;
    max-height: 500px;
    background: #FFFFFF;
    border: 3px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 2px 4px 14px rgba(77, 82, 91, 0.27);
    border-radius: 8px;
    max-width: 980px;
    padding-left: 30px;
}

input:focus, select:focus {
    outline: none;
}

.RefreshOrCreateForm-header-first {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #c0c0c0;
    /* identical to box height */
    letter-spacing: 0.01em;
    margin-top: 20px;
}

.RefreshOrCreateForm_instruments-select, .RefreshOrCreateForm_users-select, .hiddenParameters-select {
    cursor: pointer;
    font-family: 'Source Sans Pro';
    width: 320px;
    height: 44px;
    /*color: #4D525B;*/
    /*margin-bottom: 20px;*/
    margin-right: 45px;
    color: #c0c0c0;
    border-color: #BDBDBD;
    padding-left: 30px;
    border-radius: 5px;
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
    background: url('../../../assets/icons/ic-arrow-select-black.svg') center no-repeat;
    background-position-y: 15px;
    background-position-x: 96%;
    font-size: 18px;
}
.hiddenOptionBackground {
    position: relative;
    width: 318px;
    height: 110px;
    background-color: #FFF;
    border: solid 1px #C0C0C0;
    bottom: 2px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

.hiddenOptionBackground-locationUser {
    position: relative;
    z-index: 1;
    width: 93.75%;
    height: 110px;
    background-color: #fff;
    border: solid 1px #C0C0C0;
    border-radius: 4px;
}

.instrumentsRadio-first, .instrumentsRadio-second, .usersRadio-first, .usersRadio-second {
    position: relative;
    width: 100%;
    height: 49.5px;
    appearance: none;
    right: 5px;
    bottom: 3px;
}

.instrumentsRadio-second {
    border-radius: 4px;
}
.usersRadio-second {
    bottom: 22px;
}

.locationUser-first, .locationUser-second {
    position: relative;
    width: 100%;
    right: 5px;
    height: 52px;
    bottom: 3.5px;
    appearance: none;
}

.locationUser-second {
    border-radius: 4px;
}

.locationUser-first:hover, .locationUser-second:hover {
    background-color: #A7E3EF;
    cursor: pointer;
}
.usersRadio-first:hover, .usersRadio-second:hover {
    background-color: #A7E3EF;
    cursor: pointer;
}

.locationUser-first:hover + .locationUser-first-text, .locationUser-second:hover + .locationUser-second-text, .instrumentsRadio-first:hover + .instrumentsRadio-text-first, .instrumentsRadio-second:hover + .instrumentsRadio-text-second, .usersRadio-first:hover + .usersRadio-text-first, .usersRadio-second:hover + .usersRadio-text-second {
    font-weight: bold;
}

.instrumentsRadio-first:hover, .instrumentsRadio-second:hover {
    background-color: #A7E3EF;
    cursor: pointer;
}


.instrumentsRadio-second {
    bottom: 22px;
}

.instrumentsRadio-text-first, .instrumentsRadio-text-second, .usersRadio-text-first, .usersRadio-text-second {
    position: relative;
    width: 200px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: #000;
}

.locationUser-first-text, .locationUser-second-text {
    position: absolute;
    width: 300px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: #000;
}

.instrumentsRadio-text-first {
    bottom: 45px;
    left: 80px;
}

.instrumentsRadio-text-second {
    bottom: 62px;
    left: 85px;
}

.usersRadio-text-first {
    bottom: 45px;
    left: 80px;
}

.usersRadio-text-second {
    bottom: 62px;
    left: 85px;
}

.locationUser-first-text {
    left: 30px;
    bottom: 75px;
}

.locationUser-second-text {
    bottom: 15px;
    left: 30px;
}

.RefreshOrCreateForm-selects {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.RefreshOrCreateForm-header-second {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.RefreshOrCreateForm-header-second .header {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.01em;
    margin-right: 22px;
}

.modalQuestion .modalQuestion-text {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    /* identical to box height */
    color: #000;
    letter-spacing: 0.01em;
}

.RefreshOrCreateForm-header-second .modalQuestion {
    border: solid #000 1px;
    border-radius: 50%;
    padding: 2px 8px;
}

.oldUserInput {
    width: 94%;
    height: 44px;
    background: #FFFFFF;
    border: 1px solid #C0C0C0;
    box-sizing: border-box;
    border-radius: 4px;
    padding-left: 30px;
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
    font-size: 18px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    line-height: 23px;
    /* identical to box height */
    letter-spacing: 0.01em;
    margin-bottom: 20px;
}

.oldUserInput::placeholder {
    color: #c0c0c0;
}

.hiddenParameters-select {
    width: 94%;
}

.RefreshOrCreateForm-returnAccess {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 19px;
    margin-bottom: 19px;
    align-items: center;
}

.RefreshOrCreateForm-returnAccess-text {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.01em;
    color: #c0c0c0;
}

.returnAccess {
    width: 22px;
    height: 22px;
    z-index: 1;
    cursor: pointer;
    appearance: none;
}

.returnAccess-radioButton {
    position: relative;
    right: 25px;
    padding: 10px 10px;
    border: solid 2px #d7d7d7;
    border-radius: 50%;
}
.returnAccess-radioButton .hiddenSelectButton {
    display: none;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    background-color: #4FC6E0;
}

.RefreshOrCreateForm-modals {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.RefreshOrCreateForm-modals .header {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.01em;
    color: #4D525B;
}

.RefreshOrCreateForm-modals .header .modalIcon {
    border: solid #000 1px;
    border-radius: 50%;
    padding: 0px 6px;
    font-size: 15px;
    font-family: Source Sans Pro;
}

.RefreshOrCreateForm-modals .header .modalIcon:hover {
    color: #FFF;
    background-color: #4FC6E0;
    border-color: #4FC6E0;
}

.RefreshOrCreateForm-modals .hiddenModalText {
    display: none;
    position: relative;
    bottom: 50px;
    right: 50px;
    width: 252px;
    height: 55px;
    background: #FFFFFF;
    border: 1px solid #4FC6E0;
    border-radius: 3px;
    padding-left: 15px;
    padding-top: 8px;
    box-sizing: border-box;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #4D525B;
}