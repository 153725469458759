.ProgramFilesForm {
    display: flex;
    flex-direction: column;
    width: 95%;
    max-height: 1700px;
    background: #FFFFFF;
    border: 3px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 2px 4px 14px rgba(77, 82, 91, 0.27);
    border-radius: 8px;
    padding-top: 20px;
    padding-left: 33px;
    max-width: 975px;
}

.ProgramFilesForm-header {
    display: flex;
    flex-direction: column;
}

.ProgramFilesForm-header .topic {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    margin-top: 50px;
    letter-spacing: 0.01em;
    color: #333333;
    margin-bottom: 0;
}

.ProgramFilesForm-header .head, .ProgramFilesForm-body .header, .ProgramFilesForm-body-bankPrograms .header, .ProgramFilesForm-footer .header {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.01em;
    color: #4D525B;
}

.ProgramFilesForm-select {
    cursor: pointer;
    font-family: 'Source Sans Pro';
    width: 94%;
    height: 44px;
    /*color: #4D525B;*/
    margin-bottom: 40px;
    color: #C0C0C0;
    border-color: #BDBDBD;
    padding-left: 30px;
    border-radius: 5px;
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
    background: url('../../../assets/icons/ic_arrow_select.svg') center right no-repeat;
    font-size: 18px;
}

.ProgramFilesForm-body {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.ProgramFilesForm-body .header, .ProgramFilesForm-body-antivirus .header, .ProgramFilesForm-body-report .header {
    font-size: 20px;
}

.ProgramFilesForm-body-programs {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 40px;
    margin-right: 45px;
}

.ProgramFilesForm-body-programs input[type=checkbox] {
    width: 20px;
    height: 20px;
    outline-color: #C4C4C4;
}

.ProgramFilesForm-body-programs .portion {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ProgramFilesForm-body-programs .portion .adobe-image {
    width: 57px;
    margin-top: 5px;
    margin-bottom: 15px;
    background-image: url("/src/assets/icons/adobe_icon.svg");
    height: 57px;
    background-position: center;
    background-repeat: no-repeat;
}

.ProgramFilesForm-body-programs .portion .text, .microsoftIcons .text {
    margin-bottom: 15px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */

    letter-spacing: 0.01em;
}

.ProgramFilesForm-body-report {
    display: flex;
    flex-direction: column;
}

.ProgramFilesForm-body-report .checkboxes {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.ProgramFilesForm-body-report .checkboxes .info {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 70px;
    margin-top: 10px;
}

.ProgramFilesForm-body-report .checkboxes .info .report-text {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    /* identical to box height */
    color: #4D4D4F;
}

.ProgramFilesForm-body-report .checkboxes .info input[type=checkbox] {
    width: 20px;
    height: 20px;
    margin-right: 11px;
}

.ProgramFilesForm-body-bankPrograms {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
}

.ProgramFilesForm-body-bankPrograms .bankPrograms-input, .ProgramFilesForm-footer .moreProgram-input, .ProgramFilesForm-body-antivirus .moreAntivirus, .ProgramFilesForm-microsoft .mc .moreMicrosoft {
    width: 94%;
    height: 44px;
    background: #FFFFFF;
    border: 1px solid #C0C0C0;
    box-sizing: border-box;
    border-radius: 4px;
    padding-left: 30px;
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
    font-size: 18px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    line-height: 23px;
    /* identical to box height */
    letter-spacing: 0.01em;
    margin-bottom: 30px;
}

.ProgramFilesForm-body-bankPrograms .bankPrograms-input::placeholder, .ProgramFilesForm-body-antivirus .moreAntivirus::placeholder, .ProgramFilesForm-microsoft .mc .moreMicrosoft::placeholder {
    color: #C0C0C0;
}

.ProgramFilesForm-footer {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.ProgramFilesForm-footer .moreProgram-input {
    padding-top: 20px;
    height: 100px;
    resize: none;
}

.ProgramFilesForm-body-antivirus .modalIcon {
    border: solid #000 1px;
    border-radius: 50%;
    padding: 0px 7px;
    margin-left: 19px;
    font-size: 16px;
    font-family: Source Sans Pro;
    margin-right: 18px;
}

.ProgramFilesForm-body-antivirus .modals {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.ProgramFilesForm-body-antivirus .hiddenModalText {
    display: none;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    /*line-height: 15px;*/
    /* identical to box height */
    letter-spacing: 0.01em;
    color: #E34A4E;
}

.ProgramFilesForm-body-antivirus .modalIcon:hover {
    color: #FFF;
    background-color: #E34A4E;
    border-color: #E34A4E;
}

.ProgramFilesForm-hiddenOptionBackground, .ProgramFilesForm-hiddenOptionBackgroundVersion {
    position: relative;
    width: 93.6%;
    height: 225px;
    background-color: #FFF;
    border: solid 1px #C0C0C0;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    bottom: 41px;
    z-index: 0;
}

.antivirusRadio-first, .antivirusRadio-second, .antivirusRadio-third, .antivirusRadio-fourth, .version-first, .version-second, .version-third, .version-fourth, .version-fifth {
    position: relative;
    width: 100%;
    right: 5px;
    height: 52px;
    bottom: 3.5px;
    appearance: none;
}

.antivirusRadio-first-text, .antivirusRadio-second-text, .antivirusRadio-third-text, .antivirusRadio-fourth-text, .version-first-text, .version-second-text, .version-third-text, .version-fourth-text, .version-fifth-text {
    position: relative;
    width: 100px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: #4D4D4F;
}

.antivirusRadio-first:hover, .antivirusRadio-second:hover, .antivirusRadio-third:hover, .antivirusRadio-fourth:hover, .version-first:hover, .version-second:hover, .version-third:hover, .version-fourth:hover, .version-fifth:hover {
    background-color: #A7E3EF;
    cursor: pointer;
}

/*.antivirusRadio-first:hover + .antivirusRadio-first-text, .antivirusRadio-second:hover + .antivirusRadio-second-text{*/
/*    font-weight: bold;*/
/*}*/
.antivirusRadio-second {
    bottom: 28px;
}

.antivirusRadio-third {
    bottom: 52px;
}

.antivirusRadio-fourth {
    bottom: 76px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.antivirusRadio-first-text {
    left: 30px;
    bottom: 45px;
}

.antivirusRadio-second-text {
    bottom: 70px;
    left: 30px;
}

.antivirusRadio-third-text {
    left: 30px;
    bottom: 95px;
}

.antivirusRadio-fourth-text {
    left: 30px;
    bottom: 117px;
}

.programFilesCheckbox, .microsoftAppsCheckbox {
    width: 22px;
    height: 22px;
    z-index: 2;
    cursor: pointer;
    appearance: none;
}

.programFiles-radioButton, .programFiles-radioButton-second {
    position: relative;
    padding: 10px 10px;
    border: solid 2px #d7d7d7;
    border-radius: 5px;
}

.programFiles-radioButton {
    bottom: 25px;
}

.programFiles-radioButton-second {
    right: 33px;
}

.programFiles-hiddenSelectButton, .microsoftApps-hiddenSelectButton {
    border-radius: 3px;
    width: 16px;
    height: 16px;
    background-color: #4FC6E0;
}

.ProgramFilesForm-hiddenOptionBackgroundVersion {
    height: 283px;
}

.version-second {
    bottom: 28px;
}

.version-third {
    bottom: 52px;
}

.version-fourth {
    bottom: 76px;
}

.version-fifth {
    bottom: 100px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.version-first-text {
    width: 150px;
    left: 30px;
    bottom: 45px;
}

.version-second-text {
    bottom: 70px;
    left: 30px;
}

.version-third-text {
    left: 30px;
    bottom: 95px;
}

.version-fourth-text {
    left: 30px;
    bottom: 115px;
}

.version-fifth-text {
    left: 30px;
    bottom: 140px;
}
.ProgramFilesForm-microsoft {
    display: flex;
    flex-direction: column;
}

.ProgramFilesForm-microsoft-apps {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
}

.microsoftIcons {
    display: flex;
    flex-direction: column;
    flex-basis: calc(100% / 6);
    margin-right: 5px;
    text-align: center;
    margin-bottom: 20px;
    align-items: center;
}

.chrome-image, .skype-image, .winrar-image, .mc-word-image, .mc-excel-image, .mc-onenote-image, .mc-powerpoint-image, .mc-project-image, .mc-access-image, .mc-publisher-image, .mc-outlook-image, .mc-visio-image {
    background-repeat: no-repeat;
    background-position: center;
}

.chrome-image, .skype-image, .winrar-image {
    width: 57px;
    height: 57px;
    margin-bottom: 15px;
}

.mc-word-image, .mc-excel-image, .mc-onenote-image, .mc-powerpoint-image, .mc-project-image, .mc-access-image, .mc-publisher-image, .mc-outlook-image, .mc-visio-image {
    height: 51px;
    width: 47px;
}

.winrar-image {
    background-image: url("/src/assets/icons/winrar_icon.svg");
}

.chrome-image {
    background-image: url("/src/assets/icons/chrome_icon.svg");
}

.skype-image {
    background-image: url("/src/assets/icons/skype_icon.svg");
}

.mc-word-image {
    background-image: url("/src/assets/icons/mc_word-icon.svg");
}

.mc-excel-image {
    background-image: url("/src/assets/icons/mc_excel-icon.svg");
}

.mc-onenote-image {
    background-image: url("/src/assets/icons/mc_onenote-icon.svg");
}

.mc-powerpoint-image {
    background-image: url("/src/assets/icons/mc_powerpoint-icon.svg");
}

.mc-project-image {
    background-image: url("/src/assets/icons/mc_project-icon.svg");
}

.mc-access-image {
    background-image: url("/src/assets/icons/mc_access-icon.svg");
}

.mc-publisher-image {
    background-image: url("/src/assets/icons/mc_publisher-icon.svg");
}

.mc-outlook-image {
    background-image: url("/src/assets/icons/mc_outlook-icon.svg");
}

.mc-visio-image {
    background-image: url("/src/assets/icons/mc_visto-icon.svg");
}

.microsoftAppsCheckbox {
    margin-top: 20px;
    margin-left: 15px;
}

.microsoftApps-radioButton {
    position: relative;
    border: solid 2px #d7d7d7;
    border-radius: 5px;
    bottom: 26px;
    left: 6px;
}

.microsoftIcons .text {
    margin-top: 15px;
}