.FooterInfo {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    margin: 0 auto 20px;
}
.mobileWrapText {
    display: none;
}
.socialIcons {
    display: none;
}
.FooterInfo .telephone, .mobileWrapText {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 40px;
    height: 20px;
    letter-spacing: 0.01em;

    color: #C0C0C0;
}

.FooterInfo .support {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */

    letter-spacing: 0.01em;
    text-transform: uppercase;

    color: #BDBDBD;
}

@media (max-width: 1370px) {
    .FooterInfo .telephone {
        font-size: 25px;
    }
}

@media (max-width: 425px) {
    .FooterInfo {
        margin: 20px 0 0;
        text-align: center;
    }
    .FooterInfo .support {
        display: none;
    }
    .FooterInfo .telephone {
        font-size: 18px;
        font-weight: bold;
    }
    .mobileWrapText {
        display: block;
        font-size: 18px;
        font-weight: bold;
    }
    .socialIcons {
        display: flex;
        flex-direction: row;
        margin-left: 7px;
    }
    .socialIcons > * {
        margin-top: 20px;
        margin-right: 8px;
    }
}