.CommentForm {
    display: flex;
    flex-direction: column;
    align-items: end;
    width: 90%;
    max-height: 200px;
    margin-right: 50px;
}

.comment {
    padding-top: 20px;
    resize: none;
    width: 94%;
    background: #FFFFFF;
    border: 1px solid #C0C0C0;
    box-sizing: border-box;
    border-radius: 4px;
    padding-left: 30px;
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
    font-size: 18px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    line-height: 23px;
    /* identical to box height */
    letter-spacing: 0.01em;
    margin-bottom: 30px;
}
.CommentForm .submitButton {

}