.WebsiteSettingsForm {
    display: flex;
    flex-direction: column;
    width: 95%;
    max-height: 600px;
    background: #FFFFFF;
    border: 3px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 2px 4px 14px rgba(77, 82, 91, 0.27);
    border-radius: 8px;
    max-width: 975px;
    padding-left: 33px;
}

@media (max-width: 1000px) {
    .WebsiteSettingsForm-radios .inputs .WebsiteSettingsForm-radios-text {
        font-size: 18px;
    }
}


.WebsiteSettingsForm-radios {
    display: flex;
    flex-direction: column;
    margin-top: 45px;
    margin-bottom: 25px;
}
.WebsiteSettingsForm-radios .inputs {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.websiteRadioButton {
    width: 22px;
    height: 22px;
    z-index: 1;
    cursor: pointer;
    appearance: none;
}

.website-checkboxButton {
    position: relative;
    right: 25px;
    /*padding: 10px 10px;*/
    border: solid 2px #d7d7d7;
    border-radius: 5px;
}
.website-checkboxButton .website-hiddenSelectButton {
    display: none;
    border-radius: 3px;
    width: 16px;
    height: 16px;
    background-color: #4FC6E0;
}

.WebsiteSettingsForm-radios .inputs input[type = radio] {
    width: 20px;
    height: 20px;
}

.WebsiteSettingsForm-radios-text {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    margin-left: 10px;
    font-size: 24px;
    line-height: 15px;
    letter-spacing: 0.01em;
    color: #333333;
    margin-right: 45px;
}
.modalQuestion .modalQuestion-text {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    /* identical to box height */
    color: #000;
    letter-spacing: 0.01em;
}

.WebsiteSettingsForm-radios .inputs .modalQuestion {
    border: solid #000 1px;
    border-radius: 50%;
    padding: 2px 8px;
    align-self: center;
    margin-right: 30px;
}
.files-header {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.01em;

    color: #4D4D4F;
}
.mutualFiles, .classificationSelect {
    width: 53.1%;
    height: 44px;
    background: #FFFFFF;
    border: 1px solid #C0C0C0;
    box-sizing: border-box;
    padding-left: 30px;
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
    font-size: 18px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    line-height: 23px;
    /* identical to box height */
    letter-spacing: 0.01em;
    margin-bottom: 20px;
}
.mutualFiles:focus, .classificationSelect:focus {
    outline: none;
}
.mutualFiles {
    border-right: none;
    border-radius: 4px 0 0 4px;
}
.classificationSelect {
    width: 36.9%;
    border-radius: 0 4px 4px 0;
    border-left-color: #A7E3EF;
    background: #A7E3EF url('../../../assets/icons/ic-arrow-select-black.svg') no-repeat center;
    background-position-x: 90%;
}
.addDirectoryBtn {
    border: none;
    background-color: #FFF;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.01em;
    margin-left: 20px;
    color: #00ACCD;
}
.addDirectoryBtn:hover {
    cursor: pointer;
}
.directories {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}