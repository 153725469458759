.LayoutApplicationPage {
    max-width: 1170px;
    margin: 30px auto;
    display: flex;
    justify-content: space-between;
}
@media (max-width: 1370px) {
    .LayoutApplicationPage {
        max-width: 1100px;
    }
}

@media (max-width: 1270px) {
    .LayoutApplicationPage {
        max-width: 1000px;
    }
}
@media (max-width: 1170px) {
    .LayoutApplicationPage {
        max-width: 900px;
    }
}
.LayoutApplicationPage_sidebar {
    width: 30%;
    display: flex;
    flex-direction: column;
    margin-right: 30px;
    margin-bottom: 115px;
}

@media (max-width: 425px) {
    .WhiteButton--heightStabilizer, .WhiteButton-socialContent {
        display: none;
    }
    .LayoutApplicationPage_sidebar {
        margin: 0;
        width: 100%;
        /*box-shadow: 0 0 10px rgba(0, 0, 0, 0.35);*/
        z-index: 2;
    }
    .LayoutApplicationPage {
        margin: 0;
        flex-direction: column;
    }
}

.LayoutApplicationPage__prev-menu {
}
.LayoutApplicationPage__main {
    width: 70%;
    display: flex;
    flex-direction: column;
}
@media (max-width: 450px) {
    .LayoutApplicationPage__main {
        width: 100%;
    }
}

.LayoutApplicationPage__top {
    width: 100%;
    margin: 0;
    padding: 0;
}
.LayoutApplicationPage__center {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
}
.LayoutApplicationPage__bottom {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

@media (max-width: 425px) {
    .LayoutApplicationPage__bottom {
        margin: 0;
        flex-wrap: wrap;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
