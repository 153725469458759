.Access1CForm {
    display: flex;
    flex-direction: column;
    width: 95%;
    max-height: 230px;
    background: #FFFFFF;
    border: 3px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 2px 4px 14px rgba(77, 82, 91, 0.27);
    border-radius: 8px;
    max-width: 975px;
    padding-left: 33px;
    padding-top: 15px;
}

.Access1CForm .header {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.01em;
    color: #333333;
}

.Access1CForm-radios {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.Access1CForm-radios .radios {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 70px;
    margin-bottom: 20px;
}

.Access1CForm-radios .radios input[type = radio] {
    width: 20px;
    height: 20px;
}
.Access1CForm-radios-text {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    /* identical to box height */
    color: #4D4D4F;
}
.Access1CForm-location1C {
    width: 94%;
    height: 44px;
    background: #FFFFFF;
    border: 1px solid #C0C0C0;
    box-sizing: border-box;
    border-radius: 4px;
    padding-left: 30px;
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
    font-size: 18px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    line-height: 23px;
    /* identical to box height */
    letter-spacing: 0.01em;
    margin-bottom: 25px;
}
.Access1CForm-location1C::placeholder {
    color: #C0C0C0;
}
.access1cRadio {
    width: 22px;
    height: 22px;
    z-index: 1;
    cursor: pointer;
    appearance: none;
}

.access1c-radioButton {
    position: relative;
    right: 25px;
    top: 2px;
    padding: 10px 10px;
    border: solid 2px #d7d7d7;
    border-radius: 50%;
}
.access1c-radioButton .access1c-hiddenSelectButton {
    display: none;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    background-color: #4FC6E0;
}