.StatusNew {
    height: 187px;
    background: #e34a4e;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 50px;
}

.StatusNew-warn {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 60px;
    margin-bottom: 15px;
    width: 80%;
}

.StatusNew__title {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.01em;
    color: #ffffff;
}

.StatusNew_text {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.01em;
    color: #ffffff;
    margin: 0 60px 0 0;
}

.StatusNew__time {
    font-family: Source Sans Pro;
    font-style: normal;
    font-size: 130px;
    line-height: 163px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #ffffff;
}

@media (max-width: 1300px) {
    .StatusNew__time {
        font-size: 100px;
    }

    .StatusNew_text {
        font-size: 16px;
    }
}

.SpecialistWindow {
    width: 100%;
    height: 190px;
    /*min-height: 200px;*/
    background: white;
    border-radius: 8px;
    /*padding: 36px 70px;*/
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.SpecialistWindow-status-color {
    width: 25px;
    height: 100%;
    border-radius: 8px 0 0 8px;
}

.color-green {
    color: #3cc13b;
}

.color-orange {
    color: #f3bb1c;
}

.color-grey {
    color: #4b4c4d;
}

.color-aqua {
    color: #00ABCD;
}

.backColor-aqua {
    background-color: #00ABCD;
}

.backColor-grey {
    background-color: #4b4c4d;
}

.backColor-green {
    background-color: #3cc13b;
}

.backColor-orange {
    background-color: #f3bb1c;
}

.SpecialistWindow-title {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 45px;
    letter-spacing: 0.01em;
    width: 200px;
    margin-left: 25px;
}

.SpecialistOnTheWay-text {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 38px;
    width: 115%;
    /* identical to box height */
    letter-spacing: 0.01em;
    color: #4d525b;
}

.SpecialistWindow-content {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-grow: 1;
    height: 100%;
    margin-right: 30px;
    margin-left: 30px;
    justify-content: space-around;
    /*background-color: #9E071E;*/
    align-items: center;
}

.SpecialistWindow-content-avatar {
    width: 100px;
    height: 100px;
    margin-right: 20px;
}

.SpecialistWindow-content-info {
    display: flex;
    flex-direction: column;
}

.SpecialistWindow-content-info .name {
    color: #4d525b;
    font-size: 20px;
    font-weight: bold;
}

.SpecialistWindow-content-info .contact {
    display: flex;
    flex-direction: column;
}

.SpecialistWindow-content-info .department,
.phone {
    font-size: 16px;
    margin-right: 10px;
    color: #4d525b;
}

.ratingBlock {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100px;
}

.PopUp__Background {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 50%;
    left: 50%;
    margin-top: -50vh; /* Negative half of height. */
    margin-left: -50vw; /* Negative half of width. */
    z-index: 20;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
}

.PopUp__Background .ReviewPopUp__Container {
    position: fixed;
    z-index: 30;
}

.ratingBlock > * {
    margin-bottom: 15px;
}

.ratingBlock .options {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    margin-top: 15px;
}

.prompt,
.leaveOpinion {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    line-height: 15px;
    /* identical to box height */
    color: #c4c4c4;
    letter-spacing: 0.01em;
    margin-top: 16px;
}

.prompt {
    font-size: 12px;
    margin-bottom: 0;
}

.leaveOpinion {
    font-weight: 700;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border: 1px solid #bdbdbd;
    border-radius: 4px;
    margin-bottom: 0;
    padding: 9px 14px 9px 14px;
}

.likeBtn,
.dislikeBtn {
    width: 50px;
    height: 50px;
    cursor: pointer;
}

.likeBtn {
    background: url('../../../assets/icons/icon-like.svg') center no-repeat;
}

.dislikeBtn {
    background: url('../../../assets/icons/icon-dislike.svg') center no-repeat;
}

.likeBtn-active {
    background: url('../../../assets/icons/like-active.svg') center no-repeat;
}

.dislikeBtn-active {
    background: url('../../../assets/icons/icon-dislike__active.svg') center no-repeat;
}

.likeBtn:hover {
    background: url('../../../assets/icons/like-active.svg') center no-repeat;
}

.dislikeBtn:hover {
    background: url('../../../assets/icons/icon-dislike__active.svg') center no-repeat;
}

.SpecialistWindow-body {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.comment-block {
    position: relative;
}

.comment-modal_block {
    position: absolute;
    width: 250px;
    padding: 35px 25px 25px 25px;
    right: -50px;
    top:50px;
    background-color: #FFF;
    clip-path: polygon(0% 15%, 68% 14%, 78% 0, 89% 15%, 100% 15%, 100% 100%, 0 100%);
    border-radius: 5px;
    transition: all linear .25s;
}

.comment-block-bg {
    width: 200px;
    clip-path: polygon(0% 15%, 68% 14%, 78% 0, 89% 15%, 100% 15%, 100% 100%, 0 100%);
    background-color: #00ACCD;
    z-index: 2;
    position: absolute;
}

.hide-modal {
    opacity: 0;
    transition: all linear .25s;
    display: none;
}

.show-modal {
    display: block;
    opacity: 1;
    transition: all linear .25s;
}

@media (max-width: 425px) {
    .SpecialistWindow {
        flex-direction: row;
    }

    .SpecialistWindow-body {
        flex-direction: column;
        margin-left: 20px;
        align-items: flex-start;
    }

    .SpecialistWindow-title {
        width: 100%;
        margin: 0 0 15px 0;
        font-size: 24px;
    }

    .SpecialistWindow-content {
        margin: 0;
        width: 100%;
        justify-content: flex-start;
        flex-direction: row;
    }

    .SpecialistWindow-status-color {
        width: 15px;
        height: 100%;
        border-radius: 0;
    }

    .ratingBlock {
        width: 15%;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        margin-left: 20px;
        margin-top: 25px;
    }

    .ratingBlock .options {
        flex-direction: column;
    }

    .ratingBlock .options > * {
        margin-bottom: 10px;
    }

    .ratingBlock .leaveOpinion,
    .prompt {
        display: none;
    }

    .StatusNew__title {
        font-size: 12px;
    }

    .StatusNew {
        border-radius: 0;
    }

    .StatusNew_text {
        font-size: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 5; /* number of lines to show */
        line-clamp: 5;
        line-height: 1.5;
        margin: 0;
        -webkit-box-orient: vertical;
    }

    .StatusNew-warn {
        padding-left: 20px;
    }
}
