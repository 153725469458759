.ApplicationStatus {
    margin-top: 49px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 40px;
}
.ApplicationStatus__lineBlock {
    display: flex;
    margin-right: 20px;
    flex-direction: column;
    align-items: center;
}

.ApplicationStatus__circle {
    width: 38px;
    height: 38px;
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    letter-spacing: 0.01em;
    background: #E34A4E url('../../../assets/icons/ic_check.svg')center no-repeat;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(77, 82, 91, 0.5);
}

.ApplicationStatus__line {
    width: 2px;
    height: 70px;
    background: #E34A4E;
}

.ApplicationStatus__textBlock {
    margin-top: 9px;
    width: 290px;
}
.ApplicationStatus__title {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.01em;
    color: #4D525B;
    padding: 0;
    margin: 0;
}
.ApplicationStatus__text {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #4D525B;
    padding: 0;
    margin: 0 0 25px 0;
}
.ApplicationStatus__unactive {
    color: rgba(77, 82, 91, 0.5);
}
.ApplicationStatus__unactive--circle {
    background: white;
}
.ApplicationStatus__text--pushDown {
    margin-bottom: 45px;
}

/* WHEN CANCELED */
.ApplicationStatus__canceled {
    background: #828282 url('../../../assets/icons/ic_check.svg')center no-repeat;
}
.ApplicationStatus__link--whenCanceled {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-decoration-line: underline;
    color: #E34A4E;
    margin: 5px 0;
    cursor: pointer;
}
.ApplicationStatus__link--whenCanceled:active {
    color: #4D525B;
}
.ApplicationStatus__btn--hidden {
    display: none;
}
.ApplicationStatus-mobileTitle {
    display: none;
    font-size: 20px;
}
.ApplicationStatus-mobileTextBlock {
    display: none;
    margin-top: 15px;
}
@media (max-width: 425px) {
    .ApplicationStatus {
        display: flex;
        flex-direction: column;
        width: 70%;
        margin: 25px 78px 25px 66px;
    }
    .ApplicationStatus-mobileTitle {
        display: block;
    }
    .ApplicationStatus-mobileTextBlock {
        display: block;
    }
    .ApplicationStatus__line {
        width: 90px;
        height: 3px;
    }
    .ApplicationStatus__lineBlock {
        flex-direction: row;
    }
    .ApplicationStatus__textBlock {
        display: none;
    }

}

@media (max-width: 375px) {
    .ApplicationStatus {
        margin-left: 40px;
    }
}

@media (max-width: 320px) {
    .ApplicationStatus__line {
        width: 70px;
    }
}