.OpenAccessPage-inputs-background {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    /*height: 350%;*/
    width: 100%;
    background-color: #FFFFFF;
    max-width: 975px;
    border-radius: 5px;
}
.OpenAccessPage-inputs-background > * {
    margin-bottom: 50px;
}

.OpenAccessPage-inputs-background > *:last-child {
    align-self: flex-end;
    margin-right: 70px;
}

@media (max-width: 2560px) {
    .OpenAccessPage-inputs-background {
        width: 2000px;
    }
}

.OpenAccessPage {
    display: flex;
    flex-direction: row;
    justify-content: start;
    /*align-items: center;*/
}

.OpenAccessPage-header {
    display: flex;
    flex-direction: column;
    align-self: start;
    height: 150px;
    padding-left: 70px;
    margin-bottom: 0;
    margin-top: 30px;
}

.OpenAccessPage-header .leftArrow {
    border: solid black;
    border-width: 0 3px 3px 0;
    width: 10px;
    height: 10px;
    display: inline-block;
    border-radius: 1px;
    padding: 3px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    border-color: #4FC6E0;
}

.OpenAccessPage-header h2 {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 26px;
    line-height: 33px;
    letter-spacing: 0.01em;
    cursor: pointer;
    color: #4FC6E0;
}

.OpenAccessPage-header h1 {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 33px;
    letter-spacing: 0.01em;

    color: #4D4D4F;
}
@media (max-width: 1000px) {
    .OpenAccessPage-header h1 {
        font-size: 18px;
    }
}