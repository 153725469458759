.EmailSettingsForm {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 95%;
    max-height: 475px;
    background: #FFFFFF;
    border: 3px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 2px 4px 14px rgba(77, 82, 91, 0.27);
    border-radius: 8px;
}

.EmailSettingsForm-header {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-top: 20px;
    margin-left: 33px;
}

.EmailSettingsForm-title {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height */
    letter-spacing: 0.01em;
    color: #C0C0C0;
}

.EmailSettingsForm-email-settings {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.01em;

    color: #333333;
}

.EmailSettingsForm-email-input, .EmailSettingsForm-email-input-second {
    width: 94%;
    height: 44px;
    background: #FFFFFF;
    border: 1px solid #C0C0C0;
    box-sizing: border-box;
    border-radius: 4px;
    padding-left: 30px;
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
    font-size: 18px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    line-height: 23px;
    /* identical to box height */
    letter-spacing: 0.01em;
    margin-bottom: 10px;
}

.EmailSettingsForm-email-input::placeholder {
    color: #c0c0c0;
}
.hiddenErrorText {
    color: #E34A4E;
    margin-top: 25px;
}

.EmailSettingsForm-radios {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}
.EmailSettingsForm-radios input[type = "radio"] {
    /*box-sizing: border-box;*/
    width: 20px;
    height: 20px;
    cursor: pointer;
}
.EmailSettingsForm-radio-text {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    margin-left: 5px;
    margin-right: 36px;
    /* identical to box height */
    color: #4D4D4F;
}

.EmailSettingsForm-title-second {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.EmailSettingsForm-title-second .header {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.01em;
    margin-right: 22px;
}

.modalQuestion .modalQuestion-text {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 6px;
    line-height: 18px;
    /* identical to box height */
    color: #000;
    letter-spacing: 0.01em;
}

.EmailSettingsForm-title-second .modalQuestion {
    border: solid #000 1px;
    border-radius: 50%;
    padding: 0px 6px;
    font-size: 15px;
}

.EmailSettingsForm-footer {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-left: 33px;
    margin-bottom: 20px;
}
.emailRadioButton {
    width: 22px;
    height: 22px;
    z-index: 1;
    cursor: pointer;
    appearance: none;
}

.email-radioButton {
    position: relative;
    right: 25px;
    align-self: center;
    padding: 10px 10px;
    border: solid 2px #d7d7d7;
    border-radius: 50%;
}
.email-radioButton .email-hiddenSelectButton {
    display: none;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    background-color: #4FC6E0;
}